import CONFIG from "../config/default.json";
import DefaultHero from "../public/hero/default-hero.svg";
import OnINBOXHero from "../public/hero/oninbox-hero.svg";
import OnINBOXManagerHero from "../public/hero/oninbox-manager-hero.svg";
import OnDMARCHero from "../public/logo/heroes/ondmarc-hero.svg";
import BrandTrustHero from "../public/logo/heroes/brand-trust-hero.svg";
import ASMHero from "../public/logo/heroes/asm-hero.svg";
import CertificatesHero from "../public/logo/heroes/certificates-hero.svg";

import ManonLogo from "../public/logo/manon-logomark.svg";
import OnDMARCLogo from "../public/logo/ondmarc-logomark.svg";
import BrandTrustLogo from "../public/logo/brand-trust-logomark.svg";
import OnINBOXLogo from "../public/logo/oninbox-logomark.svg";
import OnINBOXManagerLogo from "../public/logo/oninbox-manager-logomark.svg";
import ASMLogo from "../public/logo/asm-logomark.svg";
import CertificatesLogo from "../public/logo/certificates-logomark.svg";
import HardenizeLogo from "../public/logo/hardenize-logomark.svg";
import OurselvesLogo from "../public/logo/ourselves-logomark.svg";
import PartnerToolsLogo from "../public/logo/partnertools-logomark.svg";
import RpcEchoLogo from "../public/logo/rpc-echo-logomark.svg";
import SiftAdminLogo from "../public/logo/siftadmin-logomark.svg";
import SipsChaosMonkeyLogo from "../public/logo/sips-chaos-monkey-logomark.svg";
import VendorSecureLogo from "../public/logo/vendor-secure-logomark.svg";
import TrustLogo from "../public/logo/trust-logomark.svg";
import PulseLogo from "../public/logo/redsift-pulse.svg";
import DefaultLogo from "../public/files/red-sift-logomark.svg";

import {
  rsiAsmHorizontal,
  rsiBrandTrustHorizontal,
  rsiCertificatesHorizontal,
  rsiOndmarcHorizontal,
} from "@redsift/icons";

export const ROOT_DOMAIN = CONFIG.DOMAIN;
export const IAM_URL = CONFIG.DOMAIN.startsWith("localhost")
  ? `http://${CONFIG.DOMAIN}`
  : `https://iam.${CONFIG.DOMAIN}`;

export const TILL_URL = CONFIG.DOMAIN.startsWith("localhost")
  ? `http://localhost:3002`
  : `https://till.${CONFIG.DOMAIN}`;

export const RPC_URL = `https://rpc.${CONFIG.DOMAIN}`;
export const SYNC_HOST = `sync.${CONFIG.DOMAIN}`;

export const DEFAULTS: any = {
  Logo: DefaultLogo,
  Hero: DefaultHero,
  tagline: "CYBERSECURITY FOR EVERYONE",
  learnmore: "https://redsift.com",
};

interface SiftList {
  [key: string]: SiftConfig;
}

export interface SiftConfig {
  name: string;
  Logo: any;
  icon?: any;
  Hero?: any;
  tagline?: string;
  learnmore?: string;
  guid: string;
  sift: object;
  canAdmin?: boolean;
  isSelfServe?: boolean;
  description?: string;
  isOnLandingPage?: boolean;
  onlyShowIfHaveAccess?: boolean;
}

export type RadarSizeMode = "PANEL" | "THEATRE";

export const PRODUCT_COLOR_MAP: any = {
  "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1":
    "var(--redsift-color-product-ondmarc)",
  "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1":
    "var(--redsift-color-product-brand-trust)",
  asm: "var(--redsift-color-product-asm)",
  certificates: "var(--redsift-color-product-certificates)",
};

// TODO: may need to refactor this.
export const SIFTS: SiftList = {
  "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1": {
    name: "Pulse",
    Logo: PulseLogo,
    tagline: "Red Sift Pulse Platform",
    guid: "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1",
    sift: {
      name: "sift-pulse",
      owner: "redsift",
    },
    isSelfServe: false,
    canAdmin: true,
    onlyShowIfHaveAccess: true,
  },
  "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1": {
    name: "OnDMARC", // Required
    Logo: OnDMARCLogo,
    Hero: OnDMARCHero,
    tagline: "Protect against phishing and BEC attacks with Red Sift OnDMARC",
    description:
      "Award-winning, cloud-based DMARC, DKIM and SPF configuration and management.",
    learnmore: "https://redsift.com/pulse-platform/ondmarc#product-showcase",
    guid: "Qvpg7LTaTMw1-Xfz1puIhH1sgs7IjV6mGb1g20XQ-5DDhyxJ.1",
    icon: rsiOndmarcHorizontal,
    sift: {
      name: "sift-dmarc-insights",
      owner: "redsift",
    },
    canAdmin: true,
    isSelfServe: true,
    isOnLandingPage: true,
  },
  "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1": {
    name: "Brand Trust",
    Logo: BrandTrustLogo,
    icon: rsiBrandTrustHorizontal,
    Hero: BrandTrustHero,
    tagline: "Protect your brand with Red Sift Brand Trust",
    description: "AI-driven brand impersonation discovery and monitoring.",
    learnmore:
      "https://redsift.com/pulse-platform/brand-trust#product-showcase",
    guid: "o1aBPkOLYJn9f3ixUOrZ0qmUDjiS0SGbo1dDLz8DRrVUGvuU.1",
    sift: {
      name: "sift-ondomain",
      owner: "redsift",
    },
    canAdmin: true,
    isOnLandingPage: true,
    isSelfServe: false,
  },
  asm: {
    name: "ASM",
    Logo: ASMLogo,
    Hero: ASMHero,
    icon: rsiAsmHorizontal,
    tagline: "Protect the growing attack surface with Red Sift ASM",
    description: "Automated, continuous attack surface management.",
    learnmore: "https://redsift.com/pulse-platform/asm#product-showcase",
    guid: "asm",
    sift: {},
    isOnLandingPage: true,
    isSelfServe: false,
  },
  "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1": {
    name: "Certificates",
    Logo: CertificatesLogo,
    Hero: CertificatesHero,
    icon: rsiCertificatesHorizontal,
    tagline: "Build your PKI security with Red Sift Certificates",
    isSelfServe: true,
    description: "Continuous certificate discovery and monitoring.",
    learnmore:
      "https://redsift.com/pulse-platform/certificates#product-showcase",
    guid: "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1",
    sift: {
      name: "sift-certificates",
      owner: "redsift",
    },
    isOnLandingPage: true,
    canAdmin: true,
  },
  hardenize: {
    name: "Hardenize",
    Logo: HardenizeLogo,
    Hero: DefaultHero,
    icon: rsiAsmHorizontal,
    tagline:
      "AUTOMATED DISCOVERY AND MONITORING OF YOUR ENTIRE NETWORK PERIMETER",
    learnmore: "https://redsift.com/products/hardenize",
    guid: "hardenize",
    sift: {},
  },
};

export const CLOSED_BETA_SIFTS: SiftList = {
  // Vendor Secure Manager
  "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1": {
    name: "Vendor Secure",
    Logo: VendorSecureLogo,
    guid: "Eo3vZZDB7FGslknmObc6aQlCjVfV9AoQghKR_Q8mf9SFZyfK.1",
    sift: {
      name: "sift-vendor-secure-manager",
      owner: "redsift",
    },
    isSelfServe: true,
  },
};

export const TOOLS: SiftList = {
  // Sift admin
  "q7venKqTTl20ytgeT6XV2nZFbGiGSyHN2PpyIeiSm0GHngx8.1": {
    name: "Sift Admin",
    Logo: SiftAdminLogo,
    guid: "q7venKqTTl20ytgeT6XV2nZFbGiGSyHN2PpyIeiSm0GHngx8.1",
    sift: {
      name: "sift-organisation-manager",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  // Partner tools
  "9x9BBXwV_SjsFMqu4y_MkhRFJHZ3zjXv5BdyqxCQF1U1H6Kx.1": {
    name: "Partner Toolbox",
    Logo: PartnerToolsLogo,
    guid: "9x9BBXwV_SjsFMqu4y_MkhRFJHZ3zjXv5BdyqxCQF1U1H6Kx.1",
    sift: {
      name: "sift-partner-toolbox",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  // Manon
  "o0WjdJWXS4B9liBFNlS7sGrefDDTqPjZq3iGyVHIgIjNGkRJ.1": {
    name: "Manon",
    Logo: ManonLogo,
    guid: "o0WjdJWXS4B9liBFNlS7sGrefDDTqPjZq3iGyVHIgIjNGkRJ.1",
    sift: {
      name: "sift-manon",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  // Sift trust
  "ojfvrL3W5JP7-m5sIuEl-FqKVwSdQ3KfWn6199yAUq3xnddu.1": {
    name: "Sift Trust",
    Logo: TrustLogo,
    guid: "ojfvrL3W5JP7-m5sIuEl-FqKVwSdQ3KfWn6199yAUq3xnddu.1",
    sift: {
      name: "sift-trust",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  // Sift Ourselves
  "kl8LGEWJkN4m8P6kgI3zBnZmRKp4vWW9BSNBXJ3O9urUMGSZ.1": {
    name: "Ourselves",
    Logo: OurselvesLogo,
    guid: "kl8LGEWJkN4m8P6kgI3zBnZmRKp4vWW9BSNBXJ3O9urUMGSZ.1",
    sift: {
      name: "sift-ourselves",
      owner: "redsift",
    },
  },
  // RPC Echo Sift
  "qhzc2s0R36XXoizbIioVgO5m324zgDhlPR8aJhcxtCr8vroa.1": {
    name: "RPC Echo",
    Logo: RpcEchoLogo,
    guid: "qhzc2s0R36XXoizbIioVgO5m324zgDhlPR8aJhcxtCr8vroa.1",
    sift: {
      name: "rpc-sift",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  // Sips Chaos Monkey Sift (platform team test sift)
  "xfHRPVUFK_m21SBIV6XVMjMJrR7-OC30vi1TJfsVCiUwejw7.1": {
    name: "Sips Chaos Monkey",
    Logo: SipsChaosMonkeyLogo,
    guid: "xfHRPVUFK_m21SBIV6XVMjMJrR7-OC30vi1TJfsVCiUwejw7.1",
    sift: {
      name: "sips-sift",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  "hq2raQUH_Ga8lH4UxN7eENcR4s7YNUP5a62BjfmVahmwKFtP.1": {
    name: "Sentinel",
    Logo: DefaultLogo,
    guid: "hq2raQUH_Ga8lH4UxN7eENcR4s7YNUP5a62BjfmVahmwKFtP.1",
    sift: {
      name: "sift-sentinel",
      owner: "redsift",
    },
    isSelfServe: true,
  },
  "G_vq-f28FpYUhVn7kOmmxlDsDciZgKti3W6e5SJjnaeGdJ-7.1": {
    name: "Canary",
    Logo: DefaultLogo,
    guid: "G_vq-f28FpYUhVn7kOmmxlDsDciZgKti3W6e5SJjnaeGdJ-7.1",
    sift: {
      name: "sift-canary",
      owner: "redsift",
    },
    isSelfServe: true,
  },
};

// Sifts that are not show in any list, but can be accessed via URL directly
export const HIDDEN_SIFTS: SiftList = {
  // Vendor Secure Agent
  "N8i0a2WlU_KLgA-ey8ovovIJUHsC6vj9vPvg-NzJKY13YNIi.1": {
    name: "Vendor Secure Agent",
    Logo: VendorSecureLogo,
    guid: "N8i0a2WlU_KLgA-ey8ovovIJUHsC6vj9vPvg-NzJKY13YNIi.1",
    sift: {
      name: "sift-vendor-secure-agent",
      owner: "redsift",
    },
    canAdmin: true,
  },
  "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1": {
    name: "OnINBOX",
    Logo: OnINBOXLogo,
    Hero: OnINBOXHero,
    tagline: "Reveal the hidden risks inside every email you receive",
    learnmore: "https://redsift.com/products/oninbox#product-showcase",
    guid: "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1",
    sift: {
      name: "sift-oninbox",
      owner: "redsift",
    },
    isSelfServe: false,
  },
  "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1": {
    name: "OnINBOX Manager",
    Logo: OnINBOXManagerLogo,
    Hero: OnINBOXManagerHero,
    tagline: "A control center for your network's email security",
    learnmore: "https://redsift.com/products/oninbox/reporting-and-dashboards",
    guid: "1TpUvROtKGDhePFVJBYVINLJWGg1JDKWlizlAj0wmrruBq4N.1",
    sift: {
      name: "sift-oninbox-admin",
      owner: "redsift",
    },
    isSelfServe: false,
  },
  certificates: {
    name: "Certificates",
    Logo: CertificatesLogo,
    Hero: CertificatesHero,
    icon: rsiCertificatesHorizontal,
    tagline: "Build your PKI security with Red Sift Certificates",
    isSelfServe: false,
    description: "Continuous certificate discovery and monitoring.",
    learnmore:
      "https://redsift.com/pulse-platform/certificates#product-showcase",
    guid: "certificates",
    sift: {
      name: "sift-certificates",
      owner: "redsift",
    },
    isOnLandingPage: false,
  },
};

export const WAITING_ROOM_SIFT_GUIDS = [
  "0Q-ZxmJp2HvJ8rRUDb9l5ze0dkEjT8LBnmNMDKMJCT8XfmFm.1",
];

export const ALL_SIFTS: any = {
  ...SIFTS,
  ...TOOLS,
  ...HIDDEN_SIFTS,
  ...CLOSED_BETA_SIFTS,
};

export const OAUTH: any = {
  // OnINBOX
  "INrw29d28M66gDWAcp522XHMTc1kFuxIlTdpoSLCn-drkCDg.1": {
    providers: ["google", "azure_active_directory"],
  },
};

export const IAM_USERID_HEADER_NAME = "x-rs-iam-userid";

// TODO: inactivity manager
export const PLUGIN_CONFIGS = [{ id: "sync-history" }];

// Kept client ID as CLOUD for compatibility reasons
export const CLIENT_ID = "cloud";

// In the past we used to support other sift classes for mobile and chrome extension contexts
// "summary" is the class for dashboards web applications
export const CLASS_SUMMARY = "summary";
export const CLASS_RADAR_CHAT_BOT = "radar-chat-bot";

// In the past we used to support other size classes for mobile, chrome extension and dashboard tiles
// now we only support full screen and will re-add other sizes when required
export const SIZE_FULL_SCREEN = { width: null, height: null };

export const RADAR_SIZE_FULL_SCREEN = { width: 200, height: null };
// Compatibility configuration - in the past OnDMARC used to be its own brand
export const REDSIFT_HEADER_PREFIX = "Redsift";

export const ANALYTICS_PARAMETERS: {
  [key: string]: string;
} = {
  utm_source: "utm_source",
  utm_medium: "utm_medium",
  utm_campaign: "utm_campaign",
  utm_term: "utm_term",
  utm_content: "utm_content",
  utm_keyword: "utm_keyword",
  _ga: "_ga",
  guid: "guid",
};

// Hardenize URL for iframe
export const HARDENIZE_IFRAME_URL = CONFIG.HARDENIZE_URL;

export const PULSE_GUID = "Ihug_f3sAzetn_RmmHcNXIVJEx-vy4FnIQ-bjat3DCf-fVER.1";
